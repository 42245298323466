import React from "react";
import { Link } from "gatsby";

export default class DetailedListItem extends React.Component {
  constructor(props) {
    super(props);

    this.connectedNarratives = this.props.narratives.edges.filter(({ node }) => {
      return (node[this.props.category]) ?
        node[this.props.category].includes(this.props.element.node.reference)
        : false;
    });

    this.connectedImages = this.props.images.edges.filter(({ node }) => {
      return (node[this.props.category]) ?
        node[this.props.category].includes(this.props.element.node.reference)
        : false;
    });

    this.connectedPerformances = this.props.performances.edges.filter(({ node }) => {
      if (this.props.categoryUnit === 'artist') {
        return node['artists'].includes(this.props.element.node.reference);
      }
      return this.props.element.node.reference === node[this.props.categoryUnit];
    });

    this.metaTags = this.metaTags.bind(this);
    this.narrativesTag = this.narrativesTag.bind(this);
  }

  narrativesTag() {
    return (this.connectedNarratives.length > 0) ? (
      <span className="tag">
        <span className="number">{this.connectedNarratives.length}</span>
        {(this.connectedNarratives.length === 1) ? ' Narrative' : ' Narratives'}
      </span>
    ) : null;
  }

  imagesTag() {
    return (this.connectedImages.length > 0) ? (
      <span className="tag">
        <span className="number">{this.connectedImages.length}</span>
        {(this.connectedImages.length === 1) ? ' Image' : ' Images'}
      </span>
    ) : null;
  }

  performancesTag() {
    return (this.connectedPerformances.length > 0) ? (
      <span className="tag">
        <span className="number">{this.connectedPerformances.length}</span>
        {(this.connectedPerformances.length === 1) ? ' Performance' : ' Performances'}
      </span>
    ) : null;
  }
  
  metaTags() {
    const metaCount = this.connectedNarratives.length + 
                      this.connectedImages.length + 
                      this.connectedPerformances.length;
    
    if (metaCount > 0) {
      return (
        <span className="detailed-list__item__meta-bar">
          {this.narrativesTag()}{this.performancesTag()}{this.imagesTag()}
        </span>
      );
    }

    return null;
  }

  render() {
    const classes = (this.props.element.node.featured > 0) ?
                    'detailed-list__item detailed-list__item__featured' :
                    'detailed-list__item';

    return (
      <li className={classes}>
        <Link to={`/${this.props.category}/${this.props.element.node.reference}`}>
          <span className="detailed-list__item__title">
            {this.props.element.node.canonical_name}
          </span>
          {this.metaTags()}
        </Link>
      </li>
    );
  }
}