import React from "react";
import { Link, StaticQuery, graphql } from "gatsby";

export default (props) => {
  return (
    <StaticQuery
      query={graphql`
        query ArtistFilterQuery {
          aCount: allArtistsJson(
            filter: {reviewed: {eq: true}, sort_name: {regex: "/^A/i"}}) {
            totalCount
          }
          bCount: allArtistsJson(
            filter: {reviewed: {eq: true}, sort_name: {regex: "/^B/i"}}) {
            totalCount
          }
          cCount: allArtistsJson(
            filter: {reviewed: {eq: true}, sort_name: {regex: "/^C/i"}}) {
            totalCount
          }
          dCount: allArtistsJson(
            filter: {reviewed: {eq: true}, sort_name: {regex: "/^D/i"}}) {
            totalCount
          }
          eCount: allArtistsJson(
            filter: {reviewed: {eq: true}, sort_name: {regex: "/^E/i"}}) {
            totalCount
          }
          fCount: allArtistsJson(
            filter: {reviewed: {eq: true}, sort_name: {regex: "/^F/i"}}) {
            totalCount
          }
          gCount: allArtistsJson(
            filter: {reviewed: {eq: true}, sort_name: {regex: "/^G/i"}}) {
            totalCount
          }
          hCount: allArtistsJson(
            filter: {reviewed: {eq: true}, sort_name: {regex: "/^H/i"}}) {
            totalCount
          }
          iCount: allArtistsJson(
            filter: {reviewed: {eq: true}, sort_name: {regex: "/^I/i"}}) {
            totalCount
          }
          jCount: allArtistsJson(
            filter: {reviewed: {eq: true}, sort_name: {regex: "/^J/i"}}) {
            totalCount
          }
          kCount: allArtistsJson(
            filter: {reviewed: {eq: true}, sort_name: {regex: "/^K/i"}}) {
            totalCount
          }
          lCount: allArtistsJson(
            filter: {reviewed: {eq: true}, sort_name: {regex: "/^L/i"}}) {
            totalCount
          }
          mCount: allArtistsJson(
            filter: {reviewed: {eq: true}, sort_name: {regex: "/^M/i"}}) {
            totalCount
          }
          nCount: allArtistsJson(
            filter: {reviewed: {eq: true}, sort_name: {regex: "/^N/i"}}) {
            totalCount
          }
          oCount: allArtistsJson(
            filter: {reviewed: {eq: true}, sort_name: {regex: "/^O/i"}}) {
            totalCount
          }
          pCount: allArtistsJson(
            filter: {reviewed: {eq: true}, sort_name: {regex: "/^P/i"}}) {
            totalCount
          }
          qCount: allArtistsJson(
            filter: {reviewed: {eq: true}, sort_name: {regex: "/^Q/i"}}) {
            totalCount
          }
          rCount: allArtistsJson(
            filter: {reviewed: {eq: true}, sort_name: {regex: "/^R/i"}}) {
            totalCount
          }
          sCount: allArtistsJson(
            filter: {reviewed: {eq: true}, sort_name: {regex: "/^S/i"}}) {
            totalCount
          }
          tCount: allArtistsJson(
            filter: {reviewed: {eq: true}, sort_name: {regex: "/^T/i"}}) {
            totalCount
          }
          uCount: allArtistsJson(
            filter: {reviewed: {eq: true}, sort_name: {regex: "/^U/i"}}) {
            totalCount
          }
          vCount: allArtistsJson(
            filter: {reviewed: {eq: true}, sort_name: {regex: "/^V/i"}}) {
            totalCount
          }
          wCount: allArtistsJson(
            filter: {reviewed: {eq: true}, sort_name: {regex: "/^W/i"}}) {
            totalCount
          }
          xCount: allArtistsJson(
            filter: {reviewed: {eq: true}, sort_name: {regex: "/^X/i"}}) {
            totalCount
          }
          yCount: allArtistsJson(
            filter: {reviewed: {eq: true}, sort_name: {regex: "/^Y/i"}}) {
            totalCount
          }
          zCount: allArtistsJson(
            filter: {reviewed: {eq: true}, sort_name: {regex: "/^Z/i"}}) {
            totalCount
          }
        }
      `}
      render={data => {
        const letters = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j',
                         'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 
                         'u', 'v', 'w', 'x', 'y', 'z'];
        
        return (
          <div className="alpha-filter">
            {(props.filtered === 'all') ? (
              <div className="alpha-filter__cell">
                <Link className="alpha-filter__filtered" to="/artists">All</Link>
              </div>
            ) : (
              <div className="alpha-filter__cell">
                <Link to="/artists">All</Link>
              </div>
            )}
            {letters.map(letter => {
              return (
                <div key={letter} className="alpha-filter__cell">
                  {(data[letter + 'Count']) ? (
                    <>
                      {(letter === props.filtered) ? (
                        <Link className="alpha-filter__letter-link alpha-filter__filtered" to={`/artists/${letter}`}>{letter}</Link>
                      ) : (
                        <Link className="alpha-filter__letter-link" to={`/artists/${letter}`}>{letter}</Link>
                      )}
                    </>
                  ) : (
                    <span className="alpha-filter__empty">{letter}</span>
                  )}
                </div>
              );
            })}
          </div>
        );
      }}
    />
  );
};