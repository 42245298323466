import React from "react";

import DetailedListItem from "./detailed-list-item";

export default (props) => (
  <ul className="detailed-list">
    {props.elements.map((element, idx) => (
      <DetailedListItem element={element}
                        categoryUnit={props.categoryUnit}
                        category={props.category}
                        images={props.images}
                        performances={props.performances}
                        narratives={props.narratives}
                        key={idx}
      />
    ))}
  </ul>
);