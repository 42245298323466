import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout/layout";

import PageContainer from "../components/layout/page-container";
import PageSection from "../components/layout/page-section";

import DetailedList from "../components/browser/detailed-list";
import ArtistsFilter from "../components/browser/artists-filter";

class ArtistsDirectoryPage extends React.Component {
  constructor(props) {
    super(props);

    this.data = props.data;
    this.pageContext = props.pageContext;

    this.groupArtistsAlphabetically = this.groupArtistsAlphabetically.bind(this);
  }

  groupArtistsAlphabetically(artists) {
    const letters = artists.map(({ node }) => {
      return node.sort_name.substring(0, 1);
    }).filter((letter, idx, self) => self.indexOf(letter) === idx);

    return letters.map((letter) => {
      return artists.filter(({ node }) => {
        return node.sort_name.substring(0, 1) === letter;
      });
    });
  }

  render() {
    const artistsWithPerformances = this.data.allArtistsJson.edges.filter(({ node }) => {
      const artistReference = node.reference;

      let hasPerformance = false;

      this.data.allPerformancesJson.edges.forEach(({ node }) => {
        if (node.artists.includes(artistReference)) {
          hasPerformance = true;
        }
      });

      return hasPerformance;
    });

    const groupedArtists = this.groupArtistsAlphabetically(artistsWithPerformances);

    return (
      <Layout>
        <PageContainer
          bottomPadding="lg"
        >
          <PageSection background="dark-accent">
            <h1 className="page-title">Artists</h1>
          </PageSection>
          <PageSection topPadding="sm">
            <ArtistsFilter filtered={this.pageContext.slug} />
          </PageSection>
          {groupedArtists.map((artists) => {
            return (
              <PageSection topPadding="md">
                <h3>{artists[0].node.sort_name.substring(0, 1)}</h3>
                <DetailedList elements={artists}
                              categoryUnit='artist'
                              category='artists'
                              images={this.data.allImagesJson}
                              performances={this.data.allPerformancesJson}
                              narratives={this.data.allNarrativesJson}
                />

              </PageSection>
            )
          })}
          <PageSection topPadding="md">
            <ArtistsFilter filtered={this.pageContext.slug} />
          </PageSection>
        </PageContainer>
      </Layout>
    );
  }
}

export default ArtistsDirectoryPage;

export const query = graphql`
  query ArtistsDirectoryQuery($filterRegex: String!) {
    allArtistsJson(
      filter: {reviewed: {eq: true}, sort_name: {regex: $filterRegex}},
      sort: {fields: [sort_name], order: ASC}
    ) {
      totalCount
      edges {
        node {
          reference
          canonical_name
          sort_name
        }
      }
    }
    allPerformancesJson(filter: {reviewed: {eq: true}}) {
      edges {
        node {
          artists
        }
      }
    }
    allNarrativesJson {
      edges {
        node {
          artists
        }
      }
    }
    allImagesJson {
      edges {
        node {
          artists
        }
      }
    }
  }
`